<template>
  <div class="center navbar examplex">
    <vs-navbar
      padding-scroll
      center-collapsed
      hide-scroll
      fixed
      notLine
      v-model="active"
    >
      <template #left>
        <img
          src="@/assets/logo.png"
          width="46"
          alt=""
          style="padding: 10px 0"
        />
        <h6 class="ml-3 mb-0 d-block d-sm-none">Falou Music</h6>
      </template>
      <vs-navbar-item
        :active="active == 'nav-home'"
        v-scroll-to="'#home'"
        id="nav-home"
      >
        Home
      </vs-navbar-item>
      <vs-navbar-item
        :active="active == 'nav-store'"
        v-scroll-to="'#store'"
        id="nav-store"
      >
        Store
      </vs-navbar-item>
      <vs-navbar-item
        :active="active == 'nav-licences'"
        v-scroll-to="'#licences'"
        id="nav-licences"
      >
        Licences
      </vs-navbar-item>
    </vs-navbar>
  </div>
</template>

<script>
export default {
  name: "NavbarComponent",
  data: () => ({
    active: "nav-home",
    bgNavbar: "#00000000"
  })
};
</script>

<style lang="scss"></style>
